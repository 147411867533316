import React, { useEffect, useState } from 'react'

import { Link } from 'gatsby'

import Layout from '../../components/layout'

import musicForVelvet from '../../images/music-for-velvet.png'

import '../../styles/common-styles.css'
import '../../styles/music-styles.css'
import '../../styles/layout-styles.css'
import '../../styles/album-styles.css'

const SimplyVelvetPage = () => {
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    let isMobile = toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })

    setIsMobile(isMobile)
  }, [])

  return (
    <Layout>
      <div className='pageTitle'>Music for Velvet</div>
      <hr className='mx-8 mt-2 mb-8 md:my-4 md:mx-64 text-white border-gray-500' />
      {!isMobile && (
        <div className='albumInfoWrapper'>
          <img className='albumInfoImage' src={musicForVelvet} alt='' />
          <div className='albumInfoRight'>
            <div className='albumInfoNotes text-gray-700'>
              <p className='my-4'>
                "Every low brass player should own this CD...It is easy to
                understand why she is in such demand as a teacher and a soloist.
                The variety of music on this album would challenge the
                interpretive skills of any tubist. She brings that off with such
                incredible ease that it is easy to forget that it is a tuba that
                is being played - in her hands. It takes on the character of a
                human voice, something that many of us attempt but that few can
                accomplish." --NACWPI Journal, Spring 2005
              </p>
              <p className='my-4'>
                "Were Velvet Brown called Velvet Bronze, her name would serve as
                an apt description of the timbres she coaxes from her unlikely
                instrument. Her musicianship, coupled with the skills of the
                composers on this offering, takes what would seem at first sight
                a novelty, a musical curio, into the realm of genuine
                music-making and that has been the story of many past Crystal
                releases." --Fanfare, Nov/Dec 2003
              </p>
              <p className='my-4'>
                "These are all important works, played at an extremely high
                level...The playing can serve as a great model, while the
                literature can serve as a goal." --ITA Journal, Oct. 2004
              </p>
              <p className='my-4'>
                <a
                  href='https://www.amazon.com/Music-Velvet-Brown/dp/B00009V3KT/ref=sr_1_4?dchild=1&qid=1612371669&refinements=p_32%3A+Velvet%5CcBrown&s=music&sr=1-4'
                  target='_blank'
                  rel='noreferrer'
                >
                  Music for Velvet can be purchased here
                </a>
              </p>
            </div>
            <div className='soundcloudWrapper' style={{ width: '100%' }}>
              <iframe
                width='100%'
                height='400'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3201010&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className='mobileAlbumInfoWrapper'>
          <img className='mobileAlbumInfoImage' src={musicForVelvet} alt='' />
          <div className='content text-gray-700'>
            <p className='my-4'>
              <iframe
                width='100%'
                height='200'
                scrolling='no'
                frameborder='no'
                allow='autoplay'
                src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/3201010&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=false'
              ></iframe>
              <div class='font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,  Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;' />
            </p>
            <p className='my-4'>
              "Every low brass player should own this CD...It is easy to
              understand why she is in such demand as a teacher and a soloist.
              The variety of music on this album would challenge the
              interpretive skills of any tubist. She brings that off with such
              incredible ease that it is easy to forget that it is a tuba that
              is being played - in her hands. It takes on the character of a
              human voice, something that many of us attempt but that few can
              accomplish." --NACWPI Journal, Spring 2005
            </p>
            <p className='my-4'>
              "Were Velvet Brown called Velvet Bronze, her name would serve as
              an apt description of the timbres she coaxes from her unlikely
              instrument. Her musicianship, coupled with the skills of the
              composers on this offering, takes what would seem at first sight a
              novelty, a musical curio, into the realm of genuine music-making
              and that has been the story of many past Crystal releases."
              --Fanfare, Nov/Dec 2003
            </p>
            <p className='my-4'>
              "These are all important works, played at an extremely high
              level...The playing can serve as a great model, while the
              literature can serve as a goal." --ITA Journal, Oct. 2004
            </p>
            <p className='my-4'>
              <a
                href='https://www.amazon.com/Music-Velvet-Brown/dp/B00009V3KT/ref=sr_1_4?dchild=1&qid=1612371669&refinements=p_32%3A+Velvet%5CcBrown&s=music&sr=1-4'
                target='_blank'
                rel='noreferrer'
              >
                Music for Velvet can be purchased here
              </a>
            </p>
          </div>
        </div>
      )}
      <div
        style={{ marginLeft: '15%', marginTop: '35px', marginBottom: '35px' }}
      >
        <Link to='/music'>←Back to Discography</Link>
      </div>
    </Layout>
  )
}

export default SimplyVelvetPage
